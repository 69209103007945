<script setup lang="ts">
import { VForm } from 'vuetify/components/VForm'
import { fileType } from '@core/utils/helpers'

const props = defineProps<Props>()

const emit = defineEmits<Emit>()
const apiUrl = useRuntimeConfig().public.apiBaseUrl
const clientStoreChatStore = useClientStoreChatStore()
interface Emit {
  (e: 'update:isCampaignVisible', value: boolean): void
}

interface Props {
  detail: object
  isAdmin: boolean
  isLogin: boolean
  isCampaignVisible: boolean
}

const userData = useCookie<any>('userData')
const router = useRouter()

const chatContent = ref('')
const campaignDetails = ref<{}>(structuredClone(toRaw(props.detail)))

const isFormValid = ref(false)
const refForm = ref<VForm>()

const sendMessage = () => {
  refForm.value?.validate().then(({ valid }) => {
    if (valid) {
      const formData = new FormData()

      formData.append('category', 11)
      formData.append('status', '未対応')
      formData.append('campaign_id', props.detail.id)
      formData.append('content', chatContent.value)

      clientStoreChatStore.store(formData, '/client_store_chats/client').then(() => {
        router.push('/clients/chat')
      })
    }
  })
}

watch(props, () => {
  campaignDetails.value = structuredClone(toRaw(props.detail))
})

const dialogModelValueUpdate = (val: boolean) => {
  emit('update:isCampaignVisible', val)
}
</script>

<template>
  <VDialog
    :width="$vuetify.display.smAndDown ? 'auto' : 560"
    scrollable
    content-class="scrollable-dialog"
    :model-value="props.isCampaignVisible"
    @update:model-value="dialogModelValueUpdate"
  >
    <DialogCloseBtn @click="dialogModelValueUpdate(false)" />

    <VCard title="イチオシ施設のお問い合わせ">
      <VCardText>
        <VRow>
          <VCol
            cols="12"
            sm="6"
            md="4"
          >
            <h4>エリア</h4>
          </VCol>
          <VCol
            cols="12"
            sm="6"
            md="8"
          >
            {{ props.detail.facility.area ? props.detail.facility.area.prefecture : "" }}
            {{ props.detail.facility.area ? props.detail.facility.area.city : "" }}
          </VCol>
        </VRow>
        <VRow>
          <VCol
            cols="12"
            sm="6"
            md="4"
          >
            <h4>カテゴリ</h4>
          </VCol>
          <VCol
            cols="12"
            sm="6"
            md="8"
          >
            {{ props.detail.type.name }}
          </VCol>
        </VRow>
        <VRow>
          <VCol
            cols="12"
            sm="6"
            md="4"
          >
            <h4>タイトル</h4>
          </VCol>
          <VCol
            cols="12"
            sm="6"
            md="8"
          >
            {{ props.detail.name }}
          </VCol>
        </VRow>
        <VRow>
          <VCol
            cols="12"
            sm="6"
            md="4"
          >
            <h4>施設名</h4>
          </VCol>
          <VCol
            cols="12"
            sm="6"
            md="8"
          >
            <NuxtLink :to="{ name: userData.role === 'admin' ? 'admins-facility-id' : 'clients-facility-id', params: { id: props.detail.facility.id } }">
              {{ props.detail.facility.name }}
            </NuxtLink>
          </VCol>
        </VRow>
        <VRow>
          <VCol
            cols="12"
            sm="6"
            md="4"
          >
            <h4>催事スペース</h4>
          </VCol>
          <VCol
            cols="12"
            sm="6"
            md="8"
          >
            <div>
              <div v-if="props.detail.facility_place_id">
                <div
                  v-for="place in props.detail.facility.places"
                  :key="place.id"
                >
                  <div v-if="props.detail.facility_place_id.includes(place.id.toString())">
                    {{ place.name }}
                  </div>
                </div>
              </div>

              <div>
                <div v-if="props.detail.is_special">
                  {{ props.detail.facility_place_special }}
                </div>
              </div>
            </div>
          </VCol>
        </VRow>
        <VRow v-if="isAdmin">
          <VCol
            cols="12"
            sm="6"
            md="4"
          >
            <h4>施設入力内容</h4>
          </VCol>
          <VCol
            cols="12"
            sm="6"
            md="8"
          >
            <p class="content-overflow-wrap">
              {{ props.detail.description }}
            </p>
          </VCol>
        </VRow>
        <VRow>
          <VCol
            cols="12"
            sm="6"
            md="4"
          >
            <h4>内容</h4>
          </VCol>
          <VCol
            cols="12"
            sm="6"
            md="8"
          >
            <p class="content-overflow-wrap">
              {{ props.detail.description_admin }}
            </p>
          </VCol>
        </VRow>
        <VRow>
          <VCol
            cols="12"
            sm="6"
            md="4"
          >
            <h4>商材</h4>
          </VCol>
          <VCol
            cols="12"
            sm="6"
            md="8"
          >
            {{ props.detail.item_id }}
          </VCol>
        </VRow>
        <VRow>
          <VCol
            cols="12"
            sm="6"
            md="4"
          >
            <h4>期間</h4>
          </VCol>
          <VCol
            cols="12"
            sm="6"
            md="8"
          >
            {{ $dayjs(props.detail.start_date).format('M月D日 (dd)') }} ~
            {{ $dayjs(props.detail.end_date).format('M月D日 (dd)') }}
          </VCol>
        </VRow>
        <VRow>
          <VCol
            cols="12"
            sm="6"
            md="4"
          >
            <h4>問い合わせ締切</h4>
          </VCol>
          <VCol
            cols="12"
            sm="6"
            md="8"
          >
            {{ $dayjs(props.detail.release_end_date).format('M月D日 (dd)') }}
          </VCol>
        </VRow>
        <VRow>
          <VCol
            cols="12"
            sm="6"
            md="4"
          >
            <h4>画像</h4>
          </VCol>
          <VCol
            cols="12"
            sm="6"
            md="8"
          >
            <div v-if="props.detail.file">
              <div
                v-for="file in props.detail.file"
                :key="file.id"
                class="field-body"
              >
                <a
                  v-if="fileType(file.url) === 'png'"
                  class="box image"
                  :href="`${apiUrl}/storage/${file.url}`"
                >
                  <VImg
                    :width="200"
                    :src="`${apiUrl}/storage/${file.url}`"
                    class="rounded"
                  />
                  <div>
                    <label>{{ file.file_name }}</label>
                  </div>
                </a>

                <a
                  v-else
                  :href="`${apiUrl}/storage/${file.url}`"
                >
                  <VAvatar
                    rounded
                    color="primary"
                    :icon="`tabler-${fileType(file.url)}`"
                  />
                  <div>
                    <label>{{ file.file_name }}</label>
                  </div>
                </a>
              </div>
            </div>
          </VCol>
        </VRow>
      </VCardText>
      <VCardActions v-if="props.detail.release_end_date && ($dayjs(props.detail.release_end_date) > $dayjs())">
        <VCardText>
          <VRow v-if="!isAdmin">
            <template v-if="isLogin">
              <VCol
                cols="12"
                sm="6"
                md="4"
              >
                <h4>問い合わせ内容</h4>
              </VCol>
              <VCol
                cols="12"
                sm="6"
                md="8"
              >
                <VForm
                  ref="refForm"
                  v-model="isFormValid"
                  @submit.prevent="sendMessage"
                >
                  <VTextarea
                    v-model="chatContent"
                    placeholder="希望日程、その他お問い合わせ内容をご入力ください。"
                    variant="solo"
                    rows="4"
                    :rules="[requiredValidator]"
                  />
                  <VBtn
                    class="mt-2"
                    variant="flat"
                    :disabled="!isFormValid"
                    @click="sendMessage"
                  >
                    問い合わせる
                  </VBtn>
                </VForm>
              </VCol>
            </template>
            <VCol
              v-else
              cols="12"
              class="d-flex flex-wrap justify-center gap-4 mt-4"
            >
              <VBtn :to="{ name: 'contact' }">
                お問い合わせ
              </VBtn>
            </VCol>
          </VRow>
        </VCardText>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<style lang="scss">
.scrollable-dialog{
  overflow: visible !important;
}
</style>
