import { defineStore } from 'pinia'

export const useCampaignStore = defineStore('campaignStore', {
  state: () => ({
    campaigns: [],
    total: 0,
    selected: {},
  }),
  actions: {
    async fetches(params: any) {
      return await useApi<any>(createUrl('/campaigns', {
        query: params,
      }))
    },

    async fetchsClient(params: any) {
      return await useApi<any>(createUrl('/campaigns/client', {
        query: params,
      }))
    },

    async fetchsDashboardClient() {
      return await useApiPublic<any>('/campaigns/client/dashboard')
    },

    async fetch(id: number) {
      return await $api<any>(`/campaigns/${id}`)
    },

    async fetchPublic(id: number) {
      return await $apiPublic<any>(`/campaigns/${id}`)
    },

    async add(data: any) {
      return await $api('/campaigns', {
        method: 'POST',
        body: data,
      })
    },

    async update(data: any) {
      return await $api(`/campaigns/${data.id}`, {
        method: 'PUT',
        body: data,
      })
    },

    async deleteFile(fileId: number) {
      return await $api(`/campaigns/file/delete/${fileId}`)
    },

    async delete(id: number) {
      return await $api(`/campaigns/${id}`, {
        method: 'DELETE',
      })
    },
  },
})
